export const Logo = require("./images/Logo.svg");
export const LogoText = require("./logo/full-text.png");

export const Facebook = require("./images/facebook.svg");
export const Instagram = require("./images/instagram.svg");
export const Pinterest = require("./images/pinterest.svg");
export const menu = require("./images/menu.svg");
export const x = require("./images/x.svg");
export const _email = require("./images/email.png");
export const message = require("./images/message.png");
export const phone = require("./images/phone.png");
export const call = require("./images/call.svg");
export const chat = require("./images/chat-header.svg");

export const LogoImage = require("./logo/logo.png");
export const LogoWithTextImage = require("./logo/full-logo.png");
export const LogoTextImage = require("./logo/full-text-1.png");
export const LogoJinagnaImage = require("./logo/jinagna.png");
export const LogoTagImage = require("./logo/house-of-diamonds.png");

export const MasterPaymentLogo = require("./payment method/master.png");
export const VisaPaymentLogo = require("./payment method/visa.png");
