import React from 'react';
// import Home from "./pages/home";
// import Other from "./pages/other";
// import RetailReport from "./pages/retail_report";
// import DetailPage from "./pages/detail_page";
// import Diamond from "./pages/Diamond";
// import filter from "./pages/filter";
// import Jewellery from "./pages/Jewellery";
// import JewelleryDetail from "./pages/detail_page";
// import DiamondDetail from "./pages/Diamond-detail";
// import BuildCustomRing from "./pages/build-custom-ring";

const contact = React.lazy(() => import('./pages/contact'))
const Home = React.lazy(() => import('./pages/home'))
const Diamond = React.lazy(() => import('./pages/Diamond'))
const Jewellery = React.lazy(() => import('./pages/Jewellery'))
const JewelleryDetail = React.lazy(() => import('./pages/detail_page'))
const DiamondDetail = React.lazy(() => import('./pages/Diamond-detail'))
const BuildCustomRing = React.lazy(() => import('./pages/build-custom-ring'))
const PrivacyPolicy = React.lazy(() => import('./pages/privacy-policy'))
const TermsConditions = React.lazy(() => import('./pages/terms-conditons'))

const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/diamond",
        component: Diamond,
    },
    {
        path: "/build/ring",
        component: BuildCustomRing,
    },
    {
        path: "/diamond/:id",
        component: DiamondDetail,
    },
    {
        path: "/show/:productType",
        component: Jewellery,
    },
    {
        path: "/show/:productType/:id",
        component: JewelleryDetail,
    },
    {
        path: "/contact",
        component: contact,
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms-and-conditions",
        component: TermsConditions,
    }
]

export default routes;