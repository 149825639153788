module.exports = {
    apiUrl: 'https://api.jinagna.co/api/',
    storageUrl: 'https://api.jinagna.co',
    envType: 'prod',
    whatsappNo: +971585987890,
    email: "sales@jinagna.co",
    isCurrencyConvertionActive: true,
    currencyInfo: {
        base: 'USD',
        activeCurrency: ['USD', 'AED'],
        rates: { "AED": 3.67301, "USD": 1 }
    }
}