/*eslint-disable*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  MasterPaymentLogo,
  VisaPaymentLogo,
} from "../../assets/images";
import "./Footer.scss";
import { whatsappNo } from "../../../env/env";
import Store1 from "../../assets/store/1.jpeg";
import Store2 from "../../assets/store/2.jpeg";
import Store3 from "../../assets/store/3.jpeg";
import Store4 from "../../assets/store/4.jpeg";

class Footer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      nav1Items: [
        {
          name: "Products",
        },
        {
          name: "Diamond",
          url: "/",
        },
        {
          name: "Pendants",
          url: "/",
        },
        {
          name: "Rings",
          url: "/",
        },
        {
          name: "Necklaces",
          url: "/",
        },
        {
          name: "Accessories",
          url: "/",
        },
      ],
      nav2Items: [
        {
          name: "Customer Care",
        },
        {
          name: "Need help?",
          url: `https://api.whatsapp.com/send?phone=${whatsappNo}&app_absent=true`,
        },
        // {
        //   name: "FAQs",
        //   url: "/"
        // },
        // {
        //   name: "Payment Terms",
        //   url: "/",
        // },
      ],
      nav3Items: [
        {
          name: "Jinagna Jewels",
        },
        {
          name: "Contact Us",
          url: "/contact",
        },
        // {
        //   name: "Terms & Conditions",
        //   url: "/terms-and-conditions",
        // },
        {
          name: "Privacy Policy",
          url: "/privacy-policy",
        },
      ],
      currentImage: 0,
      storeImages: [Store1, Store2, Store3, Store4],
    };
  }

  componentDidMount() {
    setInterval(this.switchImage, 2000);
  }

  switchImage = () => {
    if (this.state.currentImage < this.state.storeImages.length - 1) {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    } else {
      this.setState({
        currentImage: 0,
      });
    }
    return this.state.currentImage;
  };
  render() {
    const { nav2Items, nav3Items } = this.state;
    const { category } = this.props;
    return (
      <div className="footer">
        <div className="footerLayout">
          <ul>
            <li className="sections firstHeader">Products</li>
            {category.map((data, indexValue) => (
              <li key={indexValue} className={`sections`}>
                {!!data.url ? (
                  <Link
                    to={{
                      pathname:
                        data.name === "Diamond"
                          ? "/diamond"
                          : `/show/${data?.name?.toLowerCase()}?cid=${
                              data?.id
                            }`,
                      state: { categoryId: data?.id },
                    }}
                  >
                    {data.name}
                  </Link>
                ) : (
                  data.name
                )}
              </li>
            ))}
          </ul>
          <ul>
            <li className="sections firstHeader">Customer Care</li>
            <li className="sections">
              <a
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=${whatsappNo}&app_absent=true`}
              >
                Need Help?
              </a>
            </li>
            <li className="sections">
              <Link to={"/"}>Payment Terms</Link>
            </li>
            <li className="sections firstHeader"> Payment Methods </li>
            <li className="sections">
              <img
                className="social-media"
                src={MasterPaymentLogo}
                alt="Master Payment"
              />

              <img
                className="social-media"
                src={VisaPaymentLogo}
                alt="Visa Payment"
              />
            </li>
          </ul>
          <ul>
            {nav3Items.map((data, indexValue) => (
              <li
                key={indexValue}
                className={`sections ${indexValue === 0 ? "firstHeader" : " "}`}
              >
                {!!data.url ? (
                  <a href={data.url ?? "/"}>{data.name}</a>
                ) : (
                  data.name
                )}
              </li>
            ))}
          </ul>
          <ul>
            <li className="sections firstHeader"> Follow Us </li>
            <li className="sections">
              <a>
                <img className="social-media" src={Facebook} alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/houseofdiamonds_dxb/"
                target="_blank"
              >
                <img className="social-media" src={Instagram} alt="Instagram" />
              </a>
            </li>
            <li className="sections firstHeader"> Our Store </li>
            <li className="sections">
              <img
                className="social-media"
                style={{ width: "200px", objectFit: "contain" }}
                src={this.state.storeImages[this.state.currentImage]}
                alt="our store"
              />
            </li>
          </ul>
        </div>
        <div className="bottomNav">
          © JINAGNA JEWELLERY L.L.C All Rights Reserved.
        </div>
      </div>
    );
  }
}

export default Footer;
