import React, { Component } from 'react'

export default class Error404 extends Component {
    render() {
        return (
            <div>
                Error 404: Page Not found
            </div>
        )
    }
}
