import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  LogoImage,
  LogoTextImage,
  LogoText,
  menu,
  x,
  call,
  chat,
} from "../../../common/assets/images";
import floatingIcon from "../../../asset/floating_icon.svg";
import "./Header.scss";
import { currencyInfo, email, whatsappNo } from "../../../env/env";
import { getCurrentCurrency, setCurrentCurrency } from "../../services/utils";
class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isMobileView: window.innerWidth < 600,
      hidden: false,
      navItems: [
        {
          name: "Diamond",
          url: "show/diamond",
        },
        {
          name: "Pendants",
          url: "show/pendants",
        },
        {
          name: "Bracelets",
          url: "show/bracelets",
        },
        {
          name: "Rings",
          url: "show/ring",
        },
        {
          name: "Necklaces",
          url: "show/necklaces",
        },
        {
          name: "Accessories",
          url: "show/accessories",
        },
      ],
      isclick: false,
    };
  }

  handleClick = () => this.setState({ isclick: !this.state.isclick });
  closeMobileMenu = () => this.setState({ isclick: false });

  componentWillMount() {
    // When this component mounts, begin listening for scroll changes
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    // If this component is unmounted, stop listening
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = (e) => {
    if (window.pageYOffset <= 30) {
      this.setState({ hidden: false });
    } else {
      this.setState({ hidden: true });
    }
  };
  render() {
    const { isMobileView } = this.state;
    const { category } = this.props;
    const showInNavbarCategoryIds = [11, 1, 13, 14, 15, 16, 19];
    return (
      <>
        <div className={this.state.hidden ? "hide" : "top-header"}>
          <ul className="nav-options float_leftu">
            <li className="option">
              <img src={chat} alt="calling logo" />
              <a
                href={`https://api.whatsapp.com/send?phone=${whatsappNo}&app_absent=true`}
              >
                CHAT
              </a>
            </li>
            <li className="option">
              <img src={call} alt="chat logo" />
              <a href={`tel:${whatsappNo}`}>CALL: {whatsappNo}</a>
            </li>
            <li className="option">
              <i className="fa fa-envelope" style={{ marginRight: 10 }} />
              <a href={`tel:${whatsappNo}`}>EMAIL: {email}</a>
            </li>
          </ul>
          <ul className="nav-options float_rightu">
            <li className="option">
              <Link to="/contact">Contact</Link>
            </li>
            <li className="option">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://trade.jinagna.co/login"
              >
                Log in
              </a>
            </li>
            <li class="dropdown option">
              <span class="dropbtn border">{getCurrentCurrency()}</span>
              <div class="dropdown-content">
                {currencyInfo.activeCurrency.map((item) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCurrentCurrency(item);
                      window.location.reload();
                    }}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </li>
          </ul>
        </div>
        <div
          className={`${this.state.hidden ? " " : "header-margin-top"} header`}
        >
          <div className="mobile-menu" onClick={this.handleClick}>
            {this.state.isclick ? (
              <img
                className="menu-icon"
                src={x}
                onClick={this.closeMobileMenu}
                alt="jinagna-close-btn"
              />
            ) : (
              <img
                className="menu-icon"
                src={menu}
                onClick={this.closeMobileMenu}
                alt="jinagna-close-btn"
              />
            )}
          </div>

          <div
            className="logo-nav"
            style={{ justifyContent: isMobileView ? "center" : "flex-start" }}
          >
            {isMobileView ? (
              <Link to={"/"} className="logo-container">
                {this.state.hidden ? (
                  <img
                    className="logo"
                    src={LogoText}
                    alt="logo-text"
                    style={{ height: "35px" }}
                  />
                ) : (
                  <>
                    <img className="logo" src={LogoImage} alt="logo" />
                    <img
                      className="logo"
                      src={LogoTextImage}
                      alt="logo-text"
                      style={{ marginLeft: 10, height: "35px" }}
                    />
                  </>
                )}
              </Link>
            ) : (
              <Link to={"/"} className="logo-container">
                <img className="logo" src={LogoImage} alt="logo" />
                <img
                  className="logo"
                  src={LogoTextImage}
                  alt="logo-text"
                  style={{ marginLeft: 10, height: "30px" }}
                />
              </Link>
            )}
          </div>
          <ul
            className={
              this.state.isclick ? "nav-options active" : "nav-options"
            }
            style={{
              gridTemplateColumns: `repeat(${
                showInNavbarCategoryIds?.length ?? 7
              }, auto)`,
            }}
          >
            <img
              className="menu-icon close-fix"
              src={x}
              onClick={this.closeMobileMenu}
              alt="jinagna-close-btn"
            />
            <div className="mb-100"></div>
            {category
              ?.filter((item, index) =>
                showInNavbarCategoryIds.includes(item.id)
              )
              .map((data, indexValue) => {
                const pathname =
                  data.name === "Diamond"
                    ? "/diamond"
                    : `/show/${data?.name?.toLowerCase()}?cid=${data?.id}`;
                return (
                  <li
                    key={indexValue}
                    className="option"
                    onClick={this.closeMobileMenu}
                    style={{
                      // borderBottom:
                      //   Number(cid) === data?.id || window.location?.href.includes(pathname)
                      //     ? "#C28D6C 2px solid"
                      //     : "none",
                      alignItems:'center',
                      justifyContent:'center',
                      flexDirection: 'column'
                    }}
                  >
                    <Link
                      to={{
                        pathname,
                        state: { filters: { categoryId: data?.id } },
                      }}
                    >
                      {data.name}
                    </Link>
                    {window.location?.href.includes(pathname) && (
                      <div
                        style={{
                          marginTop: 5,
                          width: isMobileView ? '50%' : "100%",
                          height: 3,
                          backgroundColor: isMobileView ? '#fff' : "#C28D6C",
                          borderBottomLeftRadius: 16,
                          borderBottomRightRadius: 16,
                        }}
                      />
                    )}
                  </li>
                );
              })}
            {isMobileView && (
              <li key={100} className="option" onClick={this.closeMobileMenu}>
                <Link
                  to={{
                    pathname: "/contact",
                  }}
                >
                  {"Contact Us"}
                </Link>
              </li>
            )}
          </ul>
          <ul className="hidenonmobile_withborder">
            <li class="dropdown option">
              <span class="dropbtn">{getCurrentCurrency()}</span>
              <div class="dropdown-content">
                {currencyInfo.activeCurrency.map((item) => (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setCurrentCurrency(item);
                      window.location.reload();
                    }}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </li>
          </ul>
        </div>
        <a
          href={`https://api.whatsapp.com/send?phone=${whatsappNo}&app_absent=true`}
          className="float"
        >
          <img
            className="my-float"
            src={floatingIcon}
            alt="jinagna_floating_icon"
          />
        </a>
      </>
    );
  }
}

export default Header;
