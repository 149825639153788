import axios from "axios";
import { currencyInfo, isCurrencyConvertionActive } from "../../env/env";

export const FILE_FORMATS = [".jpg", ".png", ".jpeg", ".bmp", ".gif"];
export const VIDEO_FORMATS = [
  ".avi",
  ".flv",
  ".mkv",
  ".mov",
  ".mp4",
  ".webm",
  ".wmv",
];

export function OpenNewWindow(url, target, option = "") {
  window.open(url, target, option);
}

export const Metal_SET = [
  {
    label: "Gold",
    value: "GOLD",
    color: "#D9C16F",
    colorText: "yellow",
  },
  {
    label: "Rose Gold",
    value: "ROSE_GOLD",
    color: "#E4BDAA",
    colorText: "rose",
  },
  {
    label: "White Gold",
    value: "WHILE_GOLD",
    color: "#E6E8EB",
    colorText: "white",
  },
];

export const DIAMOND_QUALITY = [
  {
    label: "VVS",
    value: "VVS",
  },
  {
    label: "VS",
    value: "VS",
  },
  {
    label: "SI",
    value: "SI",
  },
];
export const RING_SIZE = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
];

export const DIAMOND_TYPE = [
  {
    label: "White",
    value: "SEARCH",
  },
  {
    label: "Fancy",
    value: "FANCY",
  },
];

export const getDiamondMsg = (diamond) => {
  let vMsg = `Hi there, I am interested in buying this diamond, the following are the details,\n\nStone ID: ${
    diamond.stone_id
  }\n${diamond.carat || "-"} ${diamond.shape || "-"} ${diamond.color || "-"} ${
    diamond.clarity || "-"
  } ${diamond.cut || "-"} ${diamond.polish || "-"} ${
    diamond.symmetry || "-"
  }\n`;
  vMsg += `\nFor More Details :\n${
    window.location.origin
  }/diamond/${window.btoa(diamond.stone_id)}\n------------`;
  return vMsg;
};
export const shareDiamondMsg = (diamond) => {
  let vMsg = `\nStone ID: ${diamond.stone_id}\n${diamond.carat || "-"} ${
    diamond.shape || "-"
  } ${diamond.color || "-"} ${diamond.clarity || "-"} ${diamond.cut || "-"} ${
    diamond.polish || "-"
  } ${diamond.symmetry || "-"} ${diamond.j_discount || "-"}%\n`;
  vMsg += `\nFor More Details :\n${
    window.location.origin
  }/diamond/${window.btoa(diamond.stone_id)}\n------------`;
  return vMsg;
};
export const getJewelryMsg = (jewellery) => {
  let vMsg = `${jewellery.title}\nPrice: $${jewellery.j_selling_price}`;
  vMsg += `\nRing-Size: ${jewellery.ringSize}\nDiamond-Quality: ${jewellery.diamondQuality}`;
  vMsg += `\nFor More Details :\n${window.location.origin}/show/${jewellery.productType}/${jewellery.id}\n------------`;

  return vMsg;
};
export const shareJewelryMsg = (jewellery) => {
  let msg = `\n ${jewellery.title}\nPrice: $${jewellery.j_selling_price}`;
  msg += `\nFor More Details :\n${window.location.origin}/show/${jewellery.productType}/${jewellery.id}\n------------`;
  return msg;
};

export const setUpCurrencyConvertion = () => {
  if (!localStorage.getItem('currency')) localStorage.setItem('currency', 'USD');
  // console.log(window.fx.convert(1000, {to: 'EUR'}))
  if (!isCurrencyConvertionActive) return;
  const requestURL = "https://open.er-api.com/v6/latest/USD";

  axios
    .get(requestURL, {
      params: {
        base: currencyInfo.base,
        symbols: currencyInfo.activeCurrency.join(","),
      },
    })
    .then((response) => {
      if (response.data) {
        window.fx.settings = {
          from: "USD",
          to: "USD",
        };
        // console.log(response.data);
        window.fx.base = response.data.base_code ?? currencyInfo.base;
        window.fx.rates = response.data.rates ?? currencyInfo.rates;
      }
    })
    .catch((err) => {
      window.fx.settings = {
        from: "USD",
        to: "USD",
      };
      // console.log(response.data);
      window.fx.base = currencyInfo.base;
      window.fx.rates = currencyInfo.rates;
    });
};

export const currencyConterver = (amt) => {
  if (!isCurrencyConvertionActive) return "USD " + amt;
  const to = localStorage.getItem("currency") ?? "USD";
  const Amount = window.fx.convert(amt, { to }).toFixed(2);
  return Amount ? `${to} ${Amount}` : "";
};

export const currencyConvertedValue = (amt, opt) => {
  if (!isCurrencyConvertionActive) return amt;
  const to = getCurrentCurrency() ?? "USD";
  const Amount = window.fx.convert(amt, opt ? opt : { to }).toFixed(2);
  return Amount;
};

export const getCurrentCurrency = () => localStorage.getItem("currency");
export const setCurrentCurrency = (val) =>
  localStorage.setItem("currency", val);
