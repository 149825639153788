const { apiAdapter } = require("./apiAdapter")

const getCategory = () => {
    return apiAdapter({
        url: 'public/categories',
        method: 'GET'
    })
}

const getSearchedDiamonds = (page, payload) => {
    return apiAdapter({
        url: 'public/retial/diamond/search',
        method: 'POST',
        data: payload,
        params: { page }
    })
}

const getSearchedJewellerys = (page, payload) => {
    return apiAdapter({
        url: 'public/jewellerys',
        method: 'POST',
        data: payload,
        params: { page }
    })
}

const getDiamondFilters = (payload) => {
    return apiAdapter({
        url: 'search/field',
        method: 'GET',
        params: payload
    })
}
const getVideoStories = (payload) => {
    return apiAdapter({
        url: 'file/videos',
        method: 'GET',
        params: payload
    })
}
const fetchConfig = (data) => {
    return apiAdapter({
        url: 'misc/config',
        method: 'POST',
        data
    })
}


module.exports = {
    getCategory,
    getSearchedDiamonds,
    getDiamondFilters,
    getSearchedJewellerys,
    getVideoStories,
    fetchConfig
}