import React, { Component } from 'react'
import { ReactComponent as Logo } from './logo.svg';
//Animation
import {motion} from "framer-motion";
import { LogoJinagnaImage,LogoTagImage } from "../../common/assets/images";

export default class PreLoader extends Component {
    state = {
        windowWidth: window.innerWidth,
    }
    container_ani = {
        hidden: {  },
        whileHover: { },
        animate: {
          transition: {
            staggerChildren: 0.3,
            delayChildren: 0.6,
          },
        },
      };
    
      leftTOright_ani = {
        hidden: {
          opacity: 0,
          marginLeft: -100,
        },
        animate: {
          marginLeft: 0,
          opacity: 1,
          transition: { duration: 1 },
        },
        whileHover: { scale: 1.2 },
      };
    render() {
        return (
          <div style={{display:"grid",justifyContent:"center",alignContent:"center",height:"100vh",width:"100vw"}}>
            <motion.div variants={this.hide3sec_ani} animate="animate" className="logoanimation">
                <Logo className="jinagnalogoan" />
                <motion.div variants={this.container_ani} initial="hidden" animate="animate"  className="jinagnatext">
                <motion.span variants={this.leftTOright_ani}>
                  <img
                    className="logo"
                    src={LogoJinagnaImage}
                    alt="logo-text"
                    style={{ height: 20 }}
                  />
                </motion.span>
                <motion.sub variants={this.leftTOright_ani}>
                <img
                    className="logo"
                    src={LogoTagImage}
                    alt="logo-text"
                    style={{ height: 15 }}
                  />
                </motion.sub>
                </motion.div>
            </motion.div>
            <motion.p style={{justifySelf:"center"}} initial={{opacity:0}} animate={{opacity:1,transition:{delay:1}}}>Loading...</motion.p >
          </div>
        )
    }
}
