import React, { PureComponent } from "react";
import "./App.scss";
import Footer from "./common/components/footer";
import Header from "./common/components/header";
import routes from "./routes";
import { Route, Switch } from "react-router-dom";
import Error404 from "./pages/Errors/404";
import { getCategory } from "./common/services/DiamondService";
import { Suspense } from "react";
import PreLoader from "./pages/pre-loader";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class App extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      category: []
    }
    this.getCategories();
    // if (!localStorage.getItem('c_item'))
    //   this.getCategories();
    // else {
    //   this.state.category = JSON.parse(atob(localStorage.getItem('c_item')))
    // }
  }
  getCategories = () => {
    getCategory().then(resp => {
      if (resp.data.data)
        this.setState({
          category: resp.data.data
        }, () => {
          localStorage.setItem('c_item', btoa(JSON.stringify(this.state.category)))
        })
    })
  }
  render() {
    return (
      <Suspense fallback={<PreLoader />}>
        <div className="App" roman="check roman">
          <Header category={this.state.category} />
          <div className="bodyLayout">
            <Switch>
              {
                routes.map((link, i) => (
                  <Route key={i} exact path={link.path} component={link.component} />
                ))
              }
              <Route component={Error404} />
            </Switch>
          </div>
          <Footer category={this.state.category} />
          <NotificationContainer />
        </div>
      </Suspense>
    );
  }
}

export default App;